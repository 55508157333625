/*tablet*/
@media (min-width: 601px) and (max-width: 900px) {
  .container_categoria {
    display: none;
  }
    .Ultimos .subMenu_mobile {
    display: none;
  }
  /*PAG UNICA*/
  .container_detalhes img {
    transition: .2s ease-in;
    min-width: 150px;
    max-width: 150px;
    max-height: 200px;
    min-height: 150px;
    border-radius: 10px;
  }
  .container_detalhes {
    max-width: 200px;
  }
  .container_movie h2 {
    width: 90%;
    font-size: 1.5em;
    padding: 15px;
  }
  .container_movie h1 {
    margin-top: 0%;
    font-size: 1.2em;
    font-family: 'Courier New', Courier, monospace sans-serif;
  }
  .sub_movie p {
    font-size: 1.5em;
  }
  /*end pag unica*/
  .sinopseOF {
    display: inline-block;
  } 
  /*pag serie*/
  .container_subTemporada {
    background-color: #2c2c2c;
    padding: 5px;
    margin-bottom: 10px;
  }
  .container_subTemporada a {
    text-decoration: none;
    background-color: #000000;
    color: #ff0000;
    padding: 10px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 220px 250px 210px 200px;
    margin: 0;
    padding: 0;
    font-size: 1.5em;
  }
}
/*PC*/
@media(min-width: 1230px) {

  /*quantidade de colunas no ultimos filmes*/
  .grid-container {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px 200px;
    margin: 0;
    padding: 0;
  }

  .Ultimos img {
    max-width: 300px;
  }

  .container_detalhes img {
    padding-left: 8%;
  }
}
/*PC*/
@media (min-width: 901px) {
  .container_img{
    display: inline-block;
    width: 30%;
    padding: 10px;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 200px 200px 200px 200px 200px;
    margin: 0;
    padding: 0;
  }
  /* remover logo superior pc
  .menu_container_mobile {
    display: none;
  } */
  /*menu fixo*/
  .Ultimos,
  .pag_filme,
  .pag_serie {
    position: relative;
    padding-top: 5%;
  }
  /*quantidade de colunas no ultimos filmes*/
  .grid-container {
    grid-template-columns: 200px 200px 200px 200px;
  }
  .Ultimos .subMenu_mobile {
    font-size: 1.3em;
    margin-top: -5%;
  }
  /*pag serie*/
  .container_temporada {
    margin-left: 20%;
    display: flex;
  }
  .container_subTemporada a:visited {
    color: #00ff00;
  }
  .container_subTemporada {
    background-color: #2c2c2c;
    width: 95%;
    display: flex;
    margin-bottom: 10px;
    padding: 5px;
  }
  .container_subTemporada a {
    text-decoration: none;
    background-color: #000000;
    color: #ff0000;
    padding: 10px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
    margin-left: 20%;
    margin-top: 5%;
  }
  /*Filmes centralidos home*/
  .container_detalhes {
    width: 165px;
    margin-left: 50px;
    justify-content: center;
    padding: 5px;
    max-width: 150px;
    min-width: 150px;
  }
  .container_detalhes img {
    min-width: 120px;
    max-width: 150px;
    max-height: 180px;
  }
  .Ultimos h1 {

    font-size: 1.5em;
    border-top: 1px solid #424242a2;
    padding: 5px;
  }
  /*pag serie end*/

  /* .menuOn,
  .container img {
    display: none;
    overflow: hidden;
  } */
  .seta img {
    display: none;
  }

  .container_temporada {
    display: inline-block;
  }
  .container_temporada div {
    margin-right: 3px;
  }
 
  .container_inputBarra img {
    filter: invert(100%);
    display: flex;
    width: 20px;
    height: 20px;
    margin-left: 2%;
  }
  .container_inputBarra {
    display: flex;
  }
  /*rodape*/
  .container_rodape {
    border-top: 1px solid #ff0000;
    display: flex;
    padding: 10px;
  }
}

/*Mobile*/
@media (max-width: 899px) {

  .Ultimos img {
    max-width: 150px;
  }
  .Ultimos h1 {
    font-size: 1.2em;
    padding: 0;
    margin: 0;
    padding: 15% 0 2% 0;
  }
  /*pag serie*/
  .container_subTemporada {
    background-color: #b60707;
    padding: 5px;
    margin-bottom: 10px;
  }
  .container_subTemporada a {
    text-decoration: none;
    background-color: #00000000;
    color: #ff0000;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
  }
  .container_subTemporada a:visited {
    color: #15ff00;
  }
  /*pag serie end*/
  .container_categoria {
    display: none;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 150px 150px 150px 150px;
    margin: 0;
    padding: 0;
  }
  /*PAG UNICA*/
  .container_movie {
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    padding: 0px;
    transition: .5s ease-in-out;
  }
  .container_movie img {
    margin-top: 30%;
  }
  .container_movie h2 {
    width: 90%;
    font-size: 1.1em;
    padding: 15px;
  }
  .container_movie h1 {
    margin-top: 0%;
    font-size: 1em;
    font-family: 'Courier New', Courier, monospace sans-serif;
  }
  .sub_movie {
    width: 100%;
  }
  .sinop {
    color: white;
  }
  .seta {
    display: flex;
  }
  .seta img {
    width: 20px;
    height: 20px;
    filter: invert(100%);
    margin-left: 15%;
    cursor: pointer;
  }
  .container_detalhes img {
    border-radius: 10px;
    min-width: 100px;
    max-height: 150px;
    padding-top: 5%;
  }
  .container_detalhes p {
    max-width: 120px;
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: 150px 150px 150px;
  }
  .Ultimos h1 {
    padding-top: 30%;
  }
  .Ultimos img {
    max-width: 100px;
  }

  .pag_serie {
    position: relative;
    padding-top: 25%;
  }
}

/*Mobile*/
@media (max-width: 420px) {
  /*titulo*/
  .Ultimos h2 {
    font-size: 1.2em;
    padding: 1%;
  }
  .subMenu_mobile {
    padding: 2%;
    padding-left: 5%;
  }
  .categories p {
    margin-right: 1%;
    font-weight: bold;
  }
  .container_detalhes img {
    border-radius: 10px;
    max-width: 110px;
    min-width: 110px;
    max-height: 150px;
    min-height: 100px;
    padding-top: 5%;
  }

  .container_detalhes p {
    font-size: 1.0em;
    font-weight: 200;
    font-family: Arial, Helvetica, sans-serif
  }

}

/*Mobile*/
@media (max-width: 395px) {
  /*titulo*/
  .Ultimos h2 {
    font-size: 1.0em;
    padding: 1%;
  }
  .subMenu_mobile {
    padding: 2%;
    padding-left: 4%;
  }
  .subMenu_mobile a {
    margin-right: 1%;
  }
  .container_detalhes img {
    border-radius: 10px;
    max-width: 50px;
    max-height: 150px;
    padding-top: 5%;
  }
  .container_detalhes {
    border: 1px solid #332f2f;
    background-color: #000000;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .container_detalhes p {
    font-size: 1.0em;
    font-weight: 100;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 130px 130px 135px;
    margin-left: 5px;
    margin: 0;
    padding: 0;
  }
}

/*Mobile*/
@media (max-width: 370px) {
  .Ultimos h2 {
    font-size: 1.0em;
    padding: 1%;
  }
  .subMenu_mobile {
    padding: 2%;
    padding-left: 3%;
  }
  .subMenu_mobile a {
    margin-right: 1%;
  }
  .container_detalhes img {
    border-radius: 10px;
    min-width: 60px;
    max-width: 120px;
    max-height: 150px;
    min-height: 150px;
    padding-top: 5%;
  }
  .container_detalhes {
    border: 1px solid #332f2f;
    background-color: #000000;
    min-width: 110px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 3px;
  }
  .container_detalhes p {
    font-size: 0.75em;
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 122px 122px 135px;
    margin: 0;
    margin-left: 5px;
    padding: 0;
  }
}

/*Mobile*/
@media (max-width: 350px) {
  .Ultimos h2 {
    font-size: 1.0em;
    padding: 1%;
  }
  .subMenu_mobile {
    padding: 2%;
    padding-left: 2%;
  }
  .subMenu_mobile a {
    margin-right: 1%;
  }
  .container_detalhes img {
    border-radius: 10px;
    min-width: 90px;
    max-width: 100px;
    max-height: 150px;
    min-height: 150px;
    padding-top: 5%;
  }
  .container_detalhes {
    border: 1px solid #332f2f;
    background-color: #000000;
    min-width: 110px;
    margin-bottom: 20px;
    margin-right: 20px;
  }
  .container_detalhes p {
    font-size: 0.75em;
    font-weight: 300;
    font-family: Arial, Helvetica, sans-serif;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 118px 118px 135px;
    margin: 0;
    margin-left: 5px;
    padding: 0;
  }
}