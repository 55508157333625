footer{
  color: #ffffff;
}

.container_rodape {
  border-top: 1px solid red;
  display: flex;
  padding: 10px;
  font-weight: bold;
}

.container_rodape p {
  width: 50%;
  margin-top: 7%;
  font-size: 0.8em;
  font-family: Arial, Helvetica, sans-serif;
  
}

.container_rodape img {
  max-width: 100px;
}


.container_subRodape {
  text-align: center;
  font-weight: bold;
}

.container_subRodape p {
  font-size: 0.8em;
  padding-bottom: 2px;
}

.container_l {
  text-align: center;
}

.container_l a {
  color: #ffffff;
  margin-right: 3%;
}

/*dmca*/
.dmca {
  margin-left: 20%;
  font-size: 1.0em;
  padding: 15px;
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
}

.dmca h2 {
  color: red;
}