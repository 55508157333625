.container_app{
    background-color: rgb(136, 3, 3);
    padding-top: 5px;
    text-align: center;
    align-items: center;
    color: #FFFFFF;
    width: 100%;

  }

  .container_app button{
    height: 30px;
    margin-left: 2%;
    border-radius: 10%;
    border: none;
    cursor: pointer;
  }
  