/*SubMenuMobile*/
.subMenu_mobile {
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    justify-content: flex-start;
    display: flex;
    text-align: center;
}
.subMenu_mobile a {
    color: #ffffff;
    text-decoration: none;
    padding: 5px;
    border-bottom: 1px solid #ffffff;
}

/*Exibir +-*/
.exibir {
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    margin-left: 5%;
}
.exibir:hover {
    color: #da1616;
}