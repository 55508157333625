body {
  color: #ffffff;

}

.embed_frame {
  margin-top: 3%;
}

.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* Proporção 16:9 para vídeos, ajuste conforme necessário */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.container_movie {
 
  padding: 20px;
  height: 100%;
  background: linear-gradient(30deg, rgba(0, 0, 0, 0.404), #1f1d1d, rgba(0, 0, 0, 0.404));
  filter: blur(200%);
  transition: .5s ease-in-out;
  overflow-x: hidden;
  padding: 50px;


  @media (max-width: 899px) {
    height: 100%;
    width: 100%;
    display: block;
    text-align: center;
    padding: 0px;
    padding-top: 30%;

    .seta {
      display: flex;
    }

    .seta img {
      width: 20px;
      height: 20px;
      filter: invert(100%);
      margin-left: 15%;
      cursor: pointer;
    }

  }
}

.container_movie h1 {
  text-transform: uppercase;
  font-weight: normal;
  border-bottom: 1px solid #ffffff;
}

.sub_movie {
  width: 100%;
  justify-content: center;
  display: inline-block;
  text-align: center;
}

.sub_movie p {
  border-bottom: 1px solid #ff000081;
  border-radius: 10px;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  margin-right: 20px;
}

.sinop {
  text-align: start;
}

.container_movie h2 {
  width: 70%;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.616);
  padding: 15px;
  font-size: 1.0em;
  border-radius: 20px;
}

.container_movie img {
  width: 250px;
  height: 350px;
  padding-top: 0%;
  margin-top: 20%;
}

.container_movie button {
  padding: 10px;
  font-size: 20px;
  transition: .5s ease-out;
  border: 1px solid transparent;
  margin: 3% 0 3% 2%;
  font-weight: bold;
  font-family: fantasy sans-serif;
}

.container_movie button:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.616);
  transition: .5s ease-in-out;
  cursor: pointer;
}

.sinopseOn {
  display: inline-block;
  color: #ffffff;
}

.sinopseOF {
  display: none;

  @media (min-width: 901px) {
    display: inline-block;
  }
}

.container_refresh img {
  width: 20px;
  height: 20px;
  filter: invert(200%);
  cursor: pointer;
}

/*Canais*/
.UltimosCanal {
  padding: 6% 10px 10px 10px;
}

.UltimosCanal h2 {
  font-size: 1.3em;
}