.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
}

.container p a {
  font-size: 25px;
  font-style: italic;
  font-family: 'Times New Roman', Arial, Times, sans-serif;
  text-transform: uppercase;
  text-decoration: none;
}


.container img {
  filter: invert(100%);
  margin-right: 20px;
}

.container nav img {
  width: 25px;
}



:is(.barraOFF input, .barraOFF img, .barraOFF p) {
  display: none;
}


.lupa input {
  background-color: transparent;
  border: none;
  font-size: 25px;
  color: white;
}

input:focus {
  box-shadow: 0 0 0 0;
  outline: 0;
}

/* Estilo para o menu principal "Filmes" */
.categories {
  position: relative;
  display: inline-block;
}

.categories .gen {
  cursor: pointer;
}

/* Estilo para o dropdown menu */
.categories .dropdown-menu {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: .5s;
  z-index: 1;
}

.dropdown-menu li {
  padding: 10px;
}

/* Mostrar o dropdown menu quando "Filmes" é clicado */
.categories .dropdown-menu-Active {
  display: inline-block;
  transition: .5s;
}