.Ultimos{
    color: #ffffff;
    padding-top: 10%;
}
.Ultimos h2, .Ultimos h1{
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
}
.subMenu_mobile {
    justify-content: center;
    font-weight: bold;
    align-items: center;
}
.container_detalhesPai {
    text-align: center;
    display: flex;
  font-weight: bold;

}
.container_detalhesPai a {
    display: flex;
}
.containerTitleHome {
    display: flex;
    align-items: center;
    
}
.containerTitleHome h2 {
    margin-right: 2%;
    color: #ffffff;
}
.containerTitleHome a {
    text-decoration: none;
    color: #ff0000;
    font-size: 0.9em;
    font-family: Arial, Helvetica, sans-serif;
}
.container_detalhes {
    padding: 2px;
    border: 1px solid #ffffff21;
    background-color: rgb(0, 0, 0);
    margin: 0 5% 10% 0;
}
.container_detalhes a {
    margin-right: 10%;
}

.container_detalhes img:hover {
    opacity: 0.2;
}
.container_detalhes a:hover {
    cursor: pointer;
    background-image: url('../assents/setWhit.png');
    background-repeat: no-repeat;
    background-size: 30%;
    background-position: center;
}
.container_detalhes p {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #ffffff;
}
.grid-item {
    margin: 5px;
    text-align: center;
    font-size: 1.5em;
}
.movieRecent{
    font-size: 0.8em;
}