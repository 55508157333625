.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .netflix-loading {
    font-size: 48px;
    font-weight: bold;
    color: #e50914;
    display: flex;
  }
  
  .netflix-loading span {
    animation: netflixLoading 0.8s infinite;
  }
  
  @keyframes netflixLoading {
    0% {
      opacity: 0.2;
      transform: scale(0.8);
    }
    50% {
      opacity: 1;
      transform: scale(1.2);
    }
    100% {
      opacity: 0.2;
      transform: scale(0.8);
    }
  }
  