body {
  color: #ffffff;
  
}
.container_subTemporada {
  background-color: #2c2c2c;
  padding: 5px;
  margin-bottom: 10px;
}
.container_subTemporada a {
  text-decoration: none;
  background-color: #00000000;
  color: #ff0000;
  padding: 10px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 5px;
}
.container_movie {
  display: flex;
  padding: 100px;
  height: 100%;
  background: linear-gradient(30deg, rgba(0, 0, 0, 0.404), #1f1d1d, rgba(0, 0, 0, 0.404));
  filter: blur(200%);
  transition: .5s ease-in-out;
  @media (max-width: 899px) {
    height: 950px;
    width: 100%;
    display: block;
    text-align: center;
    padding: 0px;
    .seta {
      display: flex;
    }
    .seta img {
      width: 20px;
      height: 20px;
      filter: invert(100%);
      margin-left: 15%;
      cursor: pointer;
    }}
}
.container_movie h1 {
  text-transform: uppercase;
  margin-top: 2%;
  font-weight: normal;
  border-bottom: 1px solid #ffffff;
}
.sub_movie {
  width: 100%;
  justify-content: center;
  display: inline-block;
  text-align: center;
}
.sub_movie p {
  border-bottom: 1px solid #ff000081;
  border-radius: 10px;
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  margin-right: 20px;
}
.sinop {
  margin-left: 2%;
  text-align: start;
  color: #7c7c7c;
}
.container_movie h2 {
  width: 70%;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgba(255, 255, 255, 0.616);
  padding: 15px;
  font-size: 1.0em;
  border-radius: 20px;
  margin-left: 2%;
}
.container_movie img {
  width: 250px;
  height: 350px;
  padding-top: 0%;
  margin-top: 20%;
}
.container_movie button {
  padding: 10px;
  font-size: 20px;
  transition: .5s ease-out;
  border: 1px solid transparent;
  margin: 3% 0 3% 2%;
  font-weight: bold;
  font-family: fantasy sans-serif;
}
.container_movie button:hover {
  background-color: #000000;
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.616);
  transition: .5s ease-in-out;
  cursor: pointer;
}
.sinopseOn {
  display: inline-block;
  color: #ffffff;
}
.container_refresh img {
  width: 20px;
  height: 20px;
  filter: invert(200%);
  cursor: pointer;
}
/*Drop Down*/
.container_menu {
  display: flex;
  justify-content: center;
}
.sub_menuDrop  {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  margin-left: -40px;
}
.menu {
  list-style: none;
  border: 1px solid #c0c0c0;
  float: left;
  z-index: 1;
}
.menu li {
  position: relative;
  float: left;
  border-right: 1px solid #c0c0c0;
}
.menu li {
  color: rgb(255, 255, 255);
  text-decoration: none;
  padding: 5px 10px;
  display: block;
}
.menu li:hover {
  color: rgb(255, 4, 4);
  cursor: pointer;
}
.menu li ul {
  position: absolute;
  top: 25px;
  left: 0;
  background-color: rgb(0, 0, 0);
  display: none;
}
.menu li ul li {
  border: 1px solid #c0c0c0;
  display: block;
  width: 150px;
  margin-top: 2px;
}
.menu li:hover ul,
.menu li.over ul {
  display: block;
}